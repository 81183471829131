















































































.input-bank {
  &__dipositor {
  }
  &__number {
    display: flex;
    margin-top: 5px;
    .tf_comm {
      margin-left: 5px;
    }
  }
}
